.me-block.me-RadioButtonGroup {
  .radio-button {
    flex-direction: row;
  }
}

.me-block.me-Checkbox,
.me-block.me-Subscription {
  .field-wrap {
    input[type=checkbox] {
      display: none;
      & + label:before {
        font-family: "FontAwesome";
        display: inline-block;
        content: "\f096";
        letter-spacing: 10px;
        width: 1.28571429em;
        text-align: center;
      }
      &:checked + label:before {
        content: "\f046";
      }
    }
  }
}

.me-block.me-FormProgress {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  .score-dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    z-index: 10;
  }
  .score-dot.correct {
    background-color: #7ed321;
  }
  .score-dot.incorrect, .score-dot.partial {
    background-color: #d0021b;
  }
  .score-dot.none {
    background-color: #f0f2f3;
  }
  &:after {
    content: "";
    position: absolute;
    top: 5px;
    left: 0;
    right: 0;
    height: 1px;
    background: #f0f2f3;
  }
}

.me-block.me-FormScore {
  width: 100%;
  height: 0;
  padding-top: 100%;
  position: relative;
  .left-half, .right-half {
    position: absolute;
    top: 0;
    height: 100%;
    width: 50%;
    overflow: hidden;
    z-index: 1;
  }
  .left-half {
    left: 0;
    .circle-line {
      border-right: 0;
      border-top-left-radius: 100% 50%;
      border-bottom-left-radius: 100% 50%;
      transform-origin: right center;
    }
  }
  .right-half {
    right: 0;
    .circle-line {
      border-left: 0;
      border-top-right-radius: 100% 50%;
      border-bottom-right-radius: 100% 50%;
      transform-origin: left center;
    }
  }
  .circle-line {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border: 14px solid #533090;
  }
  .circle-text {
    position: absolute;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    display:flex;
    align-items: center;
    justify-content: center;
    border: 14px solid #f0f2f3;
    border-radius: 100%;
    strong {
      @media #{$small-up} { font-size: 8vw; }
      @media #{$medium-up} { font-size: 3.6vw; }
    }
  }
  .circle-passfail-text {
    position: absolute;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    bottom:0;
    left:0;
    right:0;
    height: 30%;
  }
}

.me-FormInputSubscribe .field-wrap {
  display: flex;
  input{margin-bottom: 0 !important;}
  align-items: center;
}