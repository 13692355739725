.responsive-menu-trigger {
  .top, .mid, .bottom {
    transition: all 0.2s;
  }

  &.active {
    .top {
      top: $meun-top-bar-position;
      transform: rotate3d(0, 0, 1, 45deg);
      &.thin {
        top: $meun-top-bar-thin-position;
      }
    }
    .mid { opacity: 0; }
    .bottom {
      top: $menu-bottom-bar-position;
      transform: rotate3d(0, 0, 1, -45deg);
      &.thin{
        top: $menu-bottom-bar-thin-position;
      }
    }
  }
}
.responsive-menu {
  margin: 0;
  max-height: 0;
  flex-direction: column;
  text-align: center;
  overflow-x: hidden;
  overflow-y: auto;
  transition: all 0.2s ease;
  width: 100%;
  .menu-item {
    width: 100%;
    border-bottom: 1px dotted white;
    a {
      //color:white !important;
      display: block;
      font-size: 20px;
      padding: 10px 0;
      text-align: center;
    }
    .menu-item-wrap{
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      a{
        flex: 1;
        text-align: left;
      }
      i {
        flex-basis: 60px;
        font-size: 20px;
      }
    }
    .sub-menu{
      max-height: 0;
      overflow:hidden;
      a{padding-left: 10px;}
      .sub-menu a {padding-left: 20px;}
      & > .menu-item:last-child{border-bottom: 0 !important;}
      &.show {
        max-height: none;
      }
    }
  }
  &.show {
    z-index: 99;
  }
}
cite{display:block;}

@include text-select-colour($text-select-colour);
 // Form
@media screen and (max-width: 640px) {
  .me-Form, .bldform  {
    .row > .columns {flex: 1 1 100%;}
  }
}
// *****************
// Site Info
// *****************
.me-SiteInfo {
  @media #{$large-up} {
    .link-disable{
      pointer-events: none;
    }
  }
}

.me-block.me-Image.transition-Slide{
  position: relative;
  overflow: hidden;
  .slide{
    position: absolute;
    top:0;
    &.active {
      top: -100%;
    }
  }

  .follow {
    position: absolute;
    top: 100%;
    &.active {
      top: 0;
    }
  }
}

.me-Heading {
  @for $i from 1 through 6 {
    h#{$i} {
      white-space: pre-line;
    }
  }
}

.me-Statement {
  cite {
    white-space:pre-line;
  }
}

.layer-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left:0;
  right:0;
}