$COL1: rgba(0,0,0,0); //
$COLFLAT1: #808080; //
$COL2: #ffffff; //
$COLFLAT2: #ffffff; //
$COL3: #ffffff; //
$COLFLAT3: #ffffff; //
$COL4: #bcbec0; //
$COLFLAT4: #bcbec0; //
$COL5: #1b1b1b; //
$COLFLAT5: #1b1b1b; //
$COL6: #1b1b1b; //
$COLFLAT6: #1b1b1b; //
$COL7: #ffffff; //
$COLFLAT7: #ffffff; //
$COL8: #1b1b1b; //
$COLFLAT8: #1b1b1b; //
$COL9: #e6b357; //
$COLFLAT9: #e6b357; //
$COL10: #ffffff; //
$COLFLAT10: #ffffff; //
$COL11: #1b1b1b; //
$COLFLAT11: #1b1b1b; //
$COL12: #f2f2f2; //
$COLFLAT12: #f2f2f2; //
$COL13: #e6b357; //
$COLFLAT13: #e6b357; //
$COL14: rgba(27,27,27,0.91); //Black trans
$COLFLAT14: #242424; //Black trans
$breakpoints: (
  small: 0,
  medium: 640px,
  large: 1024px,
  xlarge: 1248px,
  xxlarge: 1936px,
);
$global-width: 1200px;
