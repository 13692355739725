body { background-color: $COL3; }
body { color: $COL6; }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
font-family: 'Montserrat';
font-weight: 700;
font-style: normal;
line-height: 1.1;
text-transform: uppercase;
letter-spacing: 0.06875em;
font-size: 32px;

@media #{$medium-up} {
font-size: 40px;

}
}
h2 {
font-family: 'Montserrat';
font-weight: 700;
font-style: normal;
line-height: 1.1;
text-transform: uppercase;
letter-spacing: 0;
font-size: 24px;

@media #{$medium-up} {
font-size: 35px;

}
}
h3 {
font-family: 'Montserrat';
font-weight: 300;
font-style: normal;
line-height: 1.1;
text-transform: uppercase;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 30px;

}
}
h4 {
font-family: 'Montserrat';
font-weight: 700;
font-style: normal;
line-height: 1.1;
text-transform: uppercase;
letter-spacing: 0.04375em;
font-size: 18px;

@media #{$medium-up} {
font-size: 23px;

}
}
h5 {
font-family: 'Montserrat';
font-weight: 700;
font-style: normal;
line-height: 1.1;
text-transform: uppercase;
letter-spacing: 0;
font-size: 20px;

}
h6 {
font-family: 'Montserrat';
font-weight: 700;
font-style: normal;
line-height: 1.1;
text-transform: uppercase;
letter-spacing: 0;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
.button {
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-body {
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-author {
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 22px;

@media #{$medium-up} {
font-size: 24px;

}
@media #{$large-up} {
font-size: 30px;

}
}
summary {
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 20px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 20px;

}
body {
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.8;
letter-spacing: 0;
font-size: 18px;

}
p { padding-bottom: 1em; }
a {color: $COL11}
a:hover {color: $COL13}
/* Secondary:2 */
.MES2 {
background-color: $COL4;
color: $COL8;
 }
/* Secondary:3 */
.MES3 {
background-color: $COL4;
color: $COL8;
 }
h1.MEC3, h2.MEC3, h3.MEC3, h4.MEC3, h5.MEC3, h6.MEC3 { color: $COL8;
 }
cite.MEC3{
color: $COL8;
}
/* Alternate:4 */
.MES4 {
background-color: $COL5;
color: $COL7;
 }
/* Alternate:5 */
.MES5 {
background-color: $COL5;
color: $COL7;
 }
h1.MEC5, h2.MEC5, h3.MEC5, h4.MEC5, h5.MEC5, h6.MEC5 { color: $COL7;
 }
cite.MEC5{
color: $COL7;
}
/* Credit:6 */
.MES6 {
background-color: $COL5;
color: $COL7;
font-size: 16.2px;
@media #{$medium-up} {
font-size: 14.4px;
};
@media #{$large-up} {
font-size: 12.6px;
};
padding: 10px 15px 15px 15px;

 }
h1.MEC6, h2.MEC6, h3.MEC6, h4.MEC6, h5.MEC6, h6.MEC6 { color: $COL7;
 }
cite.MEC6{
color: $COL7;
font-size: 16.2px;
@media #{$medium-up} {
font-size: 14.4px;
};
@media #{$large-up} {
font-size: 12.6px;
};
}
/* Popout panel:7 */
.MES7 {
background-color: $COL9;
color: $COL2;
padding: 15px;

@media #{$large-up} {
padding: 20px 40px;

}
 }
h1.MEC7, h2.MEC7, h3.MEC7, h4.MEC7, h5.MEC7, h6.MEC7 { color: $COL2;
 }
cite.MEC7{
color: $COL2;
}
/* Primary:8 */
.MES8 {
background-color: $COL9;
color: $COL10;
 }
/* Primary:9 */
.MES9 {
background-color: $COL9;
color: $COL10;
 }
h1.MEC9, h2.MEC9, h3.MEC9, h4.MEC9, h5.MEC9, h6.MEC9 { color: $COL10;
 }
cite.MEC9{
color: $COL10;
}
/* Button:10 */
.MES10 {
background-color: $COL9;
&:hover { background-color: $COL5;}
color: $COL10;
font-size: 16.8px;
@media #{$medium-up} {
font-size: 19.2px;
};
border-radius: 50px;
padding: 10px 20px;

border-width: 1px;
border-style: solid;
border-color: transparent;
 }
/* Menu:11 */
nav.me-Menu.MES11 {
& .menu-item.MEC11, & .menu-item.MEC11 > div.MEC11{ & > a.MEC11{color: $COL5;
}
 &:hover > a.MEC11{color: $COL9;
}
 }
&.horizontal > .menu-item.MEC11 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC11 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC11 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC11 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 10px 15px;}

& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Light:12 */
.MES12 {
background-color: $COL12;
 }
/* Light:13 */
.MES13 {
background-color: $COL12;
 }
/* Alternate:14 */
.MES14 {
background-color: $COL14;
color: $COL7;
font-size: 25.2px;
@media #{$medium-up} {
font-size: 28.8px;
};
@media #{$large-up} {
font-size: 36px;
};
 }
h1.MEC14, h2.MEC14, h3.MEC14, h4.MEC14, h5.MEC14, h6.MEC14 { color: $COL7;
 }
a.MEC14 { color: $COL2;
&:hover { color: $COL9; }
 }
cite.MEC14{
color: $COL7;
font-size: 25.2px;
@media #{$medium-up} {
font-size: 28.8px;
};
@media #{$large-up} {
font-size: 36px;
};
}
/* Popup Close Button:15 */
.MES15 {
background-color: $COL1;
&:hover { background-color: $COL1;}
color: $COL2;
&:hover { color: $COL2;}
padding: 5px 7px;

border-width: 1px;
border-style: solid;
border-color: $COL2;
&:hover { border-color: $COL3; }
 }
